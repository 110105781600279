function validateEmail(email){
	var emailReg = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
	var valid = emailReg.test(email);

	if(!valid) {
		return false;
	} else {
		return true;
	}
}

$( "#add_file_upload" ).click(function() {
	var n = $( ".mud_attach_a_file_add" ).children().length;
	$(".mud_attach_a_file_add").append('<input type="file" accept="application/pdf" name="file' + n + '" id="file'+ n + '">');
});

function lightACandle() {
	//validate
	var errors = 0;

	//alert($("#g-recaptcha-job-form").val());

	var v = grecaptcha.getResponse();
    if(v.length === 0)
    {
        document.getElementById('g-captcha-response-text').innerHTML="You need to prove you're human.";
    	errors = 1;
    }
    else
    {
        document.getElementById('g-captcha-response-text').innerHTML="";
        //alert('true');
    }

	// question-name
	if ($("#name").val() === "") {
    	$("#name").css("border", "1px solid red");
    	errors = 1;
    } else {
    	$("#name").css("border", "1px solid #cacaca");
    }

    // question-email
    if(validateEmail($("#email").val())){
		$("#email").css("border", "1px solid #cacaca");
	} else {
		$("#email").css("border", "1px solid red");
		errors = 1;
	}

	// question-phone
	if ($("#intention").val() === "") {
    	$("#intention").css("border", "1px solid red");
    	errors = 1;
    } else {
    	$("#intention").css("border", "1px solid #cacaca");
    }


	if (errors == 1) {
		event.preventDefault();
		//alert('errors');
	} else {
		event.default();
		//alert('no errors');
	}

}
